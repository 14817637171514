@for (breadcrumb of beforeOverflow; track breadcrumb; let last = $last) {
  @if (breadcrumb.route) {
    <a
      bitLink
      linkType="primary"
      class="tw-my-2 tw-inline-block"
      [routerLink]="breadcrumb.route"
      [queryParams]="breadcrumb.queryParams"
      [queryParamsHandling]="breadcrumb.queryParamsHandling"
    >
      <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
    </a>
  }
  @if (!breadcrumb.route) {
    <button
      type="button"
      bitLink
      linkType="primary"
      class="tw-my-2 tw-inline-block"
      (click)="breadcrumb.onClick($event)"
    >
      <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
    </button>
  }
  @if (!last) {
    <i class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
  }
}

@if (hasOverflow) {
  @if (beforeOverflow.length > 0) {
    <i class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
  }
  <button
    type="button"
    bitIconButton="bwi-ellipsis-h"
    [bitMenuTriggerFor]="overflowMenu"
    size="small"
    aria-haspopup
  ></button>
  <bit-menu #overflowMenu>
    @for (breadcrumb of overflow; track breadcrumb) {
      @if (breadcrumb.route) {
        <a
          bitMenuItem
          linkType="primary"
          [routerLink]="breadcrumb.route"
          [queryParams]="breadcrumb.queryParams"
          [queryParamsHandling]="breadcrumb.queryParamsHandling"
        >
          <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
        </a>
      }
      @if (!breadcrumb.route) {
        <button type="button" bitMenuItem linkType="primary" (click)="breadcrumb.onClick($event)">
          <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
        </button>
      }
    }
  </bit-menu>
  <i class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
  @for (breadcrumb of afterOverflow; track breadcrumb; let last = $last) {
    @if (breadcrumb.route) {
      <a
        bitLink
        linkType="primary"
        class="tw-my-2 tw-inline-block"
        [routerLink]="breadcrumb.route"
        [queryParams]="breadcrumb.queryParams"
        [queryParamsHandling]="breadcrumb.queryParamsHandling"
      >
        <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
      </a>
    }
    @if (!breadcrumb.route) {
      <button
        type="button"
        bitLink
        linkType="primary"
        class="tw-my-2 tw-inline-block"
        (click)="breadcrumb.onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
      </button>
    }
    @if (!last) {
      <i class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
    }
  }
}
