<!-- This a higher order component that composes `NavItemComponent`  -->
@if (!hideIfEmpty || nestedNavComponents.length > 0) {
  <bit-nav-item
    [text]="text"
    [icon]="icon"
    [route]="route"
    [relativeTo]="relativeTo"
    [routerLinkActiveOptions]="routerLinkActiveOptions"
    [variant]="variant"
    [treeDepth]="treeDepth"
    (mainContentClicked)="handleMainContentClicked()"
    [ariaLabel]="ariaLabel"
    [hideActiveStyles]="parentHideActiveStyles"
  >
    <ng-template #button>
      <button
        type="button"
        class="tw-ml-auto"
        [bitIconButton]="
          open ? 'bwi-angle-up' : variant === 'tree' ? 'bwi-angle-right' : 'bwi-angle-down'
        "
        [buttonType]="'light'"
        (click)="toggle($event)"
        size="small"
        [title]="'toggleCollapse' | i18n"
        aria-haspopup="true"
        [attr.aria-expanded]="open.toString()"
        [attr.aria-controls]="contentId"
        [attr.aria-label]="['toggleCollapse' | i18n, text].join(' ')"
      ></button>
    </ng-template>
    <!-- Show toggle to the left for trees otherwise to the right -->
    @if (variant === "tree") {
      <ng-container slot="start">
        <ng-container *ngTemplateOutlet="button"></ng-container>
      </ng-container>
    }
    <ng-container slot="end">
      <ng-content select="[slot=end]"></ng-content>
      @if (variant !== "tree") {
        <ng-container *ngTemplateOutlet="button"></ng-container>
      }
    </ng-container>
  </bit-nav-item>
  <!-- [attr.aria-controls] of the above button expects a unique ID on the controlled element -->
  @if (sideNavService.open$ | async) {
    @if (open) {
      <div
        [attr.id]="contentId"
        [attr.aria-label]="[text, 'submenu' | i18n].join(' ')"
        role="group"
      >
        <ng-content></ng-content>
      </div>
    }
  }
}
