<bit-tab-header>
  <div
    bitTabListContainer
    role="tablist"
    [attr.aria-label]="label"
    (keydown)="keyManager.onKeydown($event)"
  >
    @for (tab of tabs; track tab; let i = $index) {
      <button
        bitTabListItem
        type="button"
        role="tab"
        [id]="getTabLabelId(i)"
        [active]="tab.isActive"
        [disabled]="tab.disabled"
        [attr.aria-selected]="selectedIndex === i"
        [attr.tabindex]="selectedIndex === i ? 0 : -1"
        (click)="selectTab(i)"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
        <ng-template #content>
          @if (tab.templateLabel) {
            <ng-container [ngTemplateOutlet]="tab.templateLabel.templateRef"></ng-container>
          } @else {
            {{ tab.textLabel }}
          }
        </ng-template>
      </button>
    }
  </div>
</bit-tab-header>
<div class="tw-px-4 tw-pt-5">
  @for (tab of tabs; track tab; let i = $index) {
    <bit-tab-body
      role="tabpanel"
      [id]="getTabContentId(i)"
      [attr.tabindex]="tab.contentTabIndex"
      [attr.labeledby]="getTabLabelId(i)"
      [active]="tab.isActive"
      [content]="tab.content"
      [preserveContent]="preserveContent"
    >
    </bit-tab-body>
  }
</div>
