@if (label) {
  <fieldset>
    <legend class="tw-mb-1 tw-block tw-text-sm tw-font-semibold tw-text-main">
      <ng-content select="bit-label"></ng-content>
      @if (required) {
        <span class="tw-text-xs tw-font-normal"> ({{ "required" | i18n }})</span>
      }
    </legend>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </fieldset>
}

@if (!label) {
  <ng-container *ngTemplateOutlet="content"></ng-container>
}

<ng-template #content>
  <div>
    <ng-content></ng-content>
  </div>
  <ng-content select="bit-hint" ngProjectAs="bit-hint"></ng-content>
</ng-template>
