<ng-container>
  <bit-layout>
    <div class="tw-mb-1 text-primary" bitTypography="body1">{{ "accessIntelligence" | i18n }}</div>
    <h1 bitTypography="h1">{{ "riskInsights" | i18n }}</h1>
    <div class="tw-text-muted tw-max-w-4xl tw-mb-2">
      {{ "reviewAtRiskPasswords" | i18n }}
    </div>
    <div
      class="tw-bg-primary-100 tw-rounded-lg tw-w-full tw-px-8 tw-py-4 tw-my-4 tw-flex tw-items-center"
    >
      <i
        class="bwi bwi-exclamation-triangle bwi-lg tw-text-[1.2rem] text-muted"
        aria-hidden="true"
      ></i>
      <span class="tw-mx-4">{{
        "dataLastUpdated" | i18n: (dataLastUpdated$ | async | date: "MMMM d, y 'at' h:mm a")
      }}</span>
      <span class="tw-flex tw-justify-center tw-w-16">
        <a
          *ngIf="!(isRefreshing$ | async)"
          bitButton
          buttonType="unstyled"
          class="tw-border-none !tw-font-normal tw-cursor-pointer !tw-py-0"
          [bitAction]="refreshData.bind(this)"
        >
          {{ "refresh" | i18n }}
        </a>
        <span>
          <i
            *ngIf="isRefreshing$ | async"
            class="bwi bwi-spinner bwi-spin tw-text-muted tw-text-[1.2rem]"
            aria-hidden="true"
          ></i>
        </span>
      </span>
    </div>
    <bit-tab-group [(selectedIndex)]="tabIndex" (selectedIndexChange)="onTabChange($event)">
      <bit-tab label="{{ 'allApplicationsWithCount' | i18n: appsCount }}">
        <tools-all-applications></tools-all-applications>
      </bit-tab>
      <bit-tab *ngIf="isCriticalAppsFeatureEnabled">
        <ng-template bitTabLabel>
          <i class="bwi bwi-star"></i>
          {{ "criticalApplicationsWithCount" | i18n: (criticalApps$ | async)?.length ?? 0 }}
        </ng-template>
        <tools-critical-applications></tools-critical-applications>
      </bit-tab>
      <bit-tab *ngIf="showDebugTabs" label="Raw Data">
        <tools-password-health></tools-password-health>
      </bit-tab>
      <bit-tab *ngIf="showDebugTabs" label="Raw Data + members">
        <tools-password-health-members></tools-password-health-members>
      </bit-tab>
      <bit-tab *ngIf="showDebugTabs" label="Raw Data + uri">
        <tools-password-health-members-uri></tools-password-health-members-uri>
      </bit-tab>
    </bit-tab-group>

    <bit-drawer style="width: 30%" [(open)]="dataService.openDrawer">
      <ng-container *ngIf="dataService.isActiveDrawerType(drawerTypes.OrgAtRiskMembers)">
        <bit-drawer-header
          title="{{ 'atRiskMembersWithCount' | i18n: dataService.atRiskMemberDetails.length }}"
        >
        </bit-drawer-header>
        <bit-drawer-body>
          <span bitTypography="body1" class="tw-text-muted tw-text-sm">{{
            "atRiskMembersDescription" | i18n
          }}</span>
          <div class="tw-flex tw-justify-between tw-mt-2 tw-text-muted">
            <div bitTypography="body2" class="tw-font-bold">{{ "email" | i18n }}</div>
            <div bitTypography="body2" class="tw-font-bold">{{ "atRiskPasswords" | i18n }}</div>
          </div>
          <ng-container *ngFor="let member of dataService.atRiskMemberDetails">
            <div class="tw-flex tw-justify-between tw-mt-2">
              <div>{{ member.email }}</div>
              <div>{{ member.atRiskPasswordCount }}</div>
            </div>
          </ng-container>
        </bit-drawer-body>
      </ng-container>

      <ng-container *ngIf="dataService.isActiveDrawerType(drawerTypes.AppAtRiskMembers)">
        <bit-drawer-header title="{{ dataService.appAtRiskMembers.applicationName }}">
        </bit-drawer-header>
        <bit-drawer-body>
          <div bitTypography="body1" class="tw-mb-2">
            {{ "atRiskMembersWithCount" | i18n: dataService.appAtRiskMembers.members.length }}
          </div>
          <div bitTypography="body1" class="tw-text-muted tw-text-sm tw-mb-2">
            {{
              "atRiskMembersDescriptionWithApp" | i18n: dataService.appAtRiskMembers.applicationName
            }}
          </div>
          <div class="tw-mt-1">
            <ng-container *ngFor="let member of dataService.appAtRiskMembers.members">
              <div>{{ member.email }}</div>
            </ng-container>
          </div>
        </bit-drawer-body>
      </ng-container>

      <ng-container *ngIf="dataService.isActiveDrawerType(drawerTypes.OrgAtRiskApps)">
        <bit-drawer-header
          title="{{ 'atRiskApplicationsWithCount' | i18n: dataService.atRiskAppDetails.length }}"
        >
        </bit-drawer-header>

        <bit-drawer-body>
          <span bitTypography="body2" class="tw-text-muted tw-text-sm">{{
            "atRiskApplicationsDescription" | i18n
          }}</span>
          <div class="tw-flex tw-justify-between tw-mt-2 tw-text-muted">
            <div bitTypography="body2" class="tw-font-bold">{{ "application" | i18n }}</div>
            <div bitTypography="body2" class="tw-font-bold">{{ "atRiskPasswords" | i18n }}</div>
          </div>
          <ng-container *ngFor="let app of dataService.atRiskAppDetails">
            <div class="tw-flex tw-justify-between tw-mt-2">
              <div>{{ app.applicationName }}</div>
              <div>{{ app.atRiskPasswordCount }}</div>
            </div>
          </ng-container>
        </bit-drawer-body>
      </ng-container>
    </bit-drawer>
  </bit-layout>
</ng-container>
