<section
  class="tw-flex tw-w-full tw-flex-col tw-self-center tw-overflow-hidden tw-rounded-xl tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-text-main"
  [ngClass]="width"
  @fadeIn
>
  <header
    class="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-border-0 tw-border-b tw-border-solid tw-border-secondary-300 tw-p-4"
  >
    <h1
      bitDialogTitleContainer
      bitTypography="h3"
      noMargin
      class="tw-text-main tw-mb-0 tw-truncate"
    >
      {{ title }}
      @if (subtitle) {
        <span class="tw-text-muted tw-font-normal tw-text-sm">
          {{ subtitle }}
        </span>
      }
      <ng-content select="[bitDialogTitle]"></ng-content>
    </h1>
    <button
      type="button"
      bitIconButton="bwi-close"
      buttonType="main"
      size="default"
      bitDialogClose
      [attr.title]="'close' | i18n"
      [attr.aria-label]="'close' | i18n"
    ></button>
  </header>

  <div
    class="tw-relative tw-flex tw-flex-col tw-overflow-hidden"
    [ngClass]="{
      'tw-min-h-60': loading,
    }"
  >
    @if (loading) {
      <div class="tw-absolute tw-flex tw-size-full tw-items-center tw-justify-center">
        <i class="bwi bwi-spinner bwi-spin bwi-lg" [attr.aria-label]="'loading' | i18n"></i>
      </div>
    }
    <div
      [ngClass]="{
        'tw-p-4': !disablePadding,
        'tw-overflow-y-auto': !loading,
        'tw-invisible tw-overflow-y-hidden': loading,
        'tw-bg-background': background === 'default',
        'tw-bg-background-alt': background === 'alt',
      }"
    >
      <ng-content select="[bitDialogContent]"></ng-content>
    </div>
  </div>

  <footer
    class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-border-0 tw-border-t tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-4"
  >
    <ng-content select="[bitDialogFooter]"></ng-content>
  </footer>
</section>
