<div class="tw-flex tw-justify-center tw-items-center" aria-hidden="true">
  <ng-container *ngIf="data$ | async as data">
    <img
      [src]="data.image"
      [appFallbackSrc]="data.fallbackImage"
      *ngIf="data.imageEnabled && data.image"
      class="tw-size-6 tw-rounded-md"
      alt=""
      decoding="async"
      loading="lazy"
    />
    <i
      class="tw-w-6 tw-text-muted bwi bwi-lg {{ data.icon }}"
      *ngIf="!data.imageEnabled || !data.image"
    ></i>
  </ng-container>
</div>
